import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { makeStyles, Typography } from '@material-ui/core';

import RichText from 'src/components/richText';
import StepsNavigation, { GoToDashboardButton, BackButton } from '../stepsNavigation';

import { Step2BWidget } from './step2bWidget';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    maxWidth: 700,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }
}));

export default function Step2B({ handleBack }) {

  const classes = useStyles();


  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOnboarding {
        edges {
          node {
            data {
              title2b {
                html
                text
                raw
              }
              text2b {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOnboarding.edges[0].node.data;

  const handleFinish = () => {
    navigate('/me/overview/');
  };

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h1">{prismicData.title2b.text}</Typography>
        <Typography component="div" variant="subtitle1" paragraph>
          <RichText render={prismicData.text2b.raw} />
        </Typography>
      </div>

      <Step2BWidget />

      <StepsNavigation>
        <BackButton onClick={handleBack} />
        <GoToDashboardButton onClick={handleFinish} />
      </StepsNavigation>
    </>
  );
}
