import React, { Component } from 'react';

export const Step2BWidget = () => {

  class Step2BWidgetComponent extends Component {
    componentDidMount() {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(script);
    }

    render() {
      return (
        <>
          <div id="schedule_form">
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/leverlyhelp/onboarding"
              style={{ minWidth: '1000px', height: '657px', marginTop: '-66px', marginBottom: '-30px' }} />
          </div>
        </>
      );
    }
  }

  return <Step2BWidgetComponent />
}
