/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, Grid, Typography, ButtonBase } from '@material-ui/core';

import { currentStep, nextStep } from 'src/features/onboarding/slice';
import StepsNavigation, { NextButton } from 'src/features/onboarding/stepsNavigation';

import StepsImage from 'src/components/stepsImage';
import RichText from 'src/components/richText';

// import { Step2BWidget } from './step2bWidget';
import Step2B from './step2b';


const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  cardWrapper: {
    justifyContent: 'center',
  },
  card: {
    width: 261,
    height: 216,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {},
  },
  cardActive: {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
  },
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 7),
  },
  image: {
    width: 160,
    // padding: theme.spacing(2, 0),
  },
}));

const Step2 = props => {
  const classes = useStyles();
  const [techChoice, setTechChoice] = useState(null);
  const [showExtraStep, setShowExtraStep] = useState(false);

  const prismicContent = useStaticQuery(graphql`
    query {
      allPrismicOnboarding {
        edges {
          node {
            data {
              title2 {
                html
                text
                raw
              }
              text2 {
                html
                text
                raw
              }
            }
          }
        }
      }
    }
  `);

  const prismicData = prismicContent.allPrismicOnboarding.edges[0].node.data;

  const handleNext = () => {
    if (techChoice) {
      setShowExtraStep(true);
    } else {
      props.nextStep();
    }
  };

  const handleBack = () => {
    setShowExtraStep(false);
  };

  useEffect(() => {
    props.currentStep({ activeStep: 2 });
  }, []);

  return !showExtraStep ? (
    <>
      <div className={classes.container}>
        <Typography variant="h1">{prismicData.title2.text}</Typography>
        <Typography component="div" variant="subtitle1" paragraph>
          <RichText render={prismicData.text2.raw} />
        </Typography>
        <Grid container spacing={3} className={classes.cardWrapper}>
          <Grid item xs={12} md="auto">
            <div className={clsx(classes.card, techChoice === true && classes.cardActive)}>
              <ButtonBase
                className={classes.cardContent}
                onClick={() => {
                  setTechChoice(true);
                }}
              >
                <StepsImage step="step2A" className={classes.image} />
                <Typography variant="h6">Assist me</Typography>
              </ButtonBase>
            </div>
          </Grid>
          <Grid item xs={12} md="auto">
            <div className={clsx(classes.card, techChoice === false && classes.cardActive)}>
              <ButtonBase
                className={classes.cardContent}
                onClick={() => {
                  setTechChoice(false);
                }}
              >
                <StepsImage step="step2B" className={classes.image} />
                <Typography variant="h6">Will do it myself</Typography>
              </ButtonBase>
            </div>
          </Grid>
        </Grid>
      </div>

      <StepsNavigation>
        <NextButton disabled={techChoice === null} onClick={handleNext} />
      </StepsNavigation>
    </>
  ) : (
    <Step2B handleBack={handleBack} />
    // <Step2BWidget />
  );
};

const mapDispatchToProps = { currentStep, nextStep };

export default connect(null, mapDispatchToProps)(Step2);
