import React from 'react';

import SEO from 'src/components/seo';

import Step2 from 'src/features/onboarding/steps/step2';

export default function Onboarding() {
  return (
    <>
      <SEO title="Step 2" />

      <Step2 />
    </>
  );
}
